<template>
  <b-card>
    <b-form id="outsideCompanyAttachmentForm" class="edit-form mt-2">

      <!--基本信息-->
      <b-col md="12">
        <b-card header="基本信息">
          <b-row>
            <b-col md="12">
              <div class="inner-card-title">附件</div>
            </b-col>

            <b-col md="12">
              <b-form-group
                  label-cols="1"
                  label-cols-lg="1"
                  label="营业执照扫描件"
                  label-for="attachments_USCC_id"
                  label-size="sm"
                  class="mb-1"
              >
                <attachment-upload v-if="company.loaded" :theme="'files'"
                                   :attachment_id="'attachments_uscc_id'"
                                   :id="company.attachments_uscc_id"
                                   :object_type="'company'"
                                   :object_id=object_id
                                   @change="onUploaded"
                />
              </b-form-group>
            </b-col>
            <b-col md="12">
              <b-form-group
                  label-cols="1"
                  label-cols-lg="1"
                  label="周年报表/存续证明"
                  label-for="attachments_GSC_id"
                  label-size="sm"
                  class="mb-1"
              >
                <attachment-upload v-if="company.loaded" :theme="'files'"
                                   :attachment_id="'attachments_gsc_id'"
                                   :id="company.attachments_gsc_id"
                                   :object_type="'company'"
                                   :object_id=object_id
                                   @change="onUploaded"
                />
              </b-form-group>
            </b-col>
            <b-col md="12">
              <b-form-group
                  label-cols="1"
                  label-cols-lg="1"
                  label="产品链路证明"
                  label-for="attachments_link"
                  label-size="sm"
                  class="mb-1"
              >
                <attachment-upload v-if="company.loaded" :theme="'files'"
                                   :attachment_id="'attachments_link'"
                                   :id="company.attachments_link"
                                   :object_type="'company'"
                                   :object_id=object_id
                                   @change="onUploaded"
                />
              </b-form-group>
            </b-col>
            <b-col md="12">
              <b-form-group
                  label-cols="1"
                  label-cols-lg="1"
                  label="历史交易资料"
                  label-for="attachments_history"
                  label-size="sm"
                  class="mb-1"
              >
                <attachment-upload v-if="company.loaded" :theme="'files'"
                                   :attachment_id="'attachments_history'"
                                   :id="company.attachments_history"
                                   :object_type="'company'"
                                   :object_id="0"
                                   @change="onUploaded"
                />
                <!--            <b-form-input-->
                <!--                id="attachments_history"-->
                <!--                size="sm"-->
                <!--                v-model="company.attachments_history"-->
                <!--                readonly-->
                <!--            />-->
              </b-form-group>
            </b-col>
            <b-col md="12">
              <b-form-group
                  label-cols="1"
                  label-cols-lg="1"
                  label="法人身份证/护照"
                  label-for="attachments_IDCard"
                  label-size="sm"
                  class="mb-1"
              >
                <attachment-upload v-if="company.loaded" :theme="'files'"
                                   :attachment_id="'attachments_idcard'"
                                   :id="company.attachments_idcard"
                                   :object_type="'company'"
                                   :object_id=object_id
                                   @change="onUploaded"
                />
              </b-form-group>
            </b-col>
            <b-col md="12">
              <b-form-group
                  label-cols="1"
                  label-cols-lg="1"
                  label="一般纳税人资格"
                  label-for="attachments_General"
                  label-size="sm"
                  class="mb-1"
              >
                <attachment-upload v-if="company.loaded" :theme="'files'"
                                   :attachment_id="'attachments_general'"
                                   :id="company.attachments_general"
                                   :object_type="'company'"
                                   :object_id=object_id
                                   @change="onUploaded"
                />
              </b-form-group>
            </b-col>
            <b-col md="12">
              <b-form-group
                  label-cols="1"
                  label-cols-lg="1"
                  label="其他资料"
                  label-for="attachments_other"
                  label-size="sm"
                  class="mb-1"
              >
                <attachment-upload v-if="company.loaded" :theme="'files'"
                                   :attachment_id="'attachments_other'"
                                   :id="company.attachments_other"
                                   :object_type="'company'"
                                   :object_id=object_id
                                   @change="onUploaded"
                />
              </b-form-group>
            </b-col>


            <b-col
                cols="12"
                class="mt-50"
            >
              <b-button
                  variant="primary"
                  class="mr-1"
                  @click="save"
              >
                保存
              </b-button>
              <b-button
                  variant="outline-secondary"
                  @click="cancel"
              >
                取消
              </b-button>
            </b-col>
          </b-row>
        </b-card>
      </b-col>


    </b-form>
  </b-card>
</template>

<script>
import {
  BCard, BMedia, BAvatar, BCardText, BForm, BRow, BCol, BFormGroup, BFormInput, BImg, BFormFile, BLink, BButton,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import {onUnmounted, ref} from '@vue/composition-api'
import store from "@/store";
import {useToast} from 'vue-toastification/composition'
import ModalSelect from "@/views/components/modal/ModalSelect";
import {
  toTime,
  toDate,
  getCodeOptions,
  getCode,
  getCodeLabel,
  getCodeColor,
  addNumber,
  isEmpty
} from '@core/utils/filter'
import companyStore from "@/views/apps/company/companyStore";
import attachmentUpload from "@/views/apps/attachment/AttachmentUpload";
import axios from '@axios'

export default {
  components: {
    BCard,
    BMedia,
    BAvatar,
    BCardText,
    BForm,
    BLink,
    BImg,
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormInput,
    BFormFile,
    vSelect,
    useToast,
    addNumber,
    isEmpty,
    Number,
    ModalSelect,
    attachmentUpload,
  },
  data() {
    return {
      object_id:0,
      company: ref({}),
    }
  },
  props:{
    company_id:Number,
  },
  setup(props) {
    const toast = useToast()

    // Register module
    if (!store.hasModule('companystore')) store.registerModule('companystore', companyStore)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule('companystore')) store.unregisterModule('companystore')
    })

    const edit = function () {
      this.object_id = props.company_id
      store.dispatch('companystore/edit', {id: props.company_id}).then(res => {
        this.company = res.data.data
      })
    }

    const cancel = function () {
      this.$router.go(-1)
    }

    const save = function () {
      if(props.company_id == 0){
        toast.error('请先填写 主体信息')
        return
      }

      this.company.company_id = props.company_id
      axios.post('api/company/saveAttachment',this.company).then(res =>{
        if (res.data.code == 0) {
          toast.success('数据已保存,可进行其他操作!')
        } else {
          toast.error(res.data.data)
        }
      })
    }

    // attachment：上传文件信息
    const onUploaded = function (id, attachment, result) {
      this.company[id] = result
    }

    return {
      edit,
      cancel,
      save,
      onUploaded,
      getCodeOptions,
      getCode,
      getCodeLabel,
      getCodeColor,
      isEmpty,
      Number,
    }
  },
  created() {
    this.edit()
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
