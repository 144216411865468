<template>
  <div>
    <b-tabs>
      <b-tab title="主体信息">
        <outside-company-entity :company_id="company_id" v-on:push_company_id="getCompanyId($event)"></outside-company-entity>
      </b-tab>

      <b-tab title="联系人信息">
        <outside-company-contact :company_id="company_id"></outside-company-contact>
      </b-tab>

      <b-tab title="银行账户">
        <outside-company-bak-acc :company_id="company_id"></outside-company-bak-acc>
      </b-tab>

      <b-tab title="账期信息">
        <outside-company-warehouse-statement :company_id="company_id"></outside-company-warehouse-statement>
      </b-tab>

      <b-tab title="证照附件">
        <outside-company-attachments :company_id="company_id"></outside-company-attachments>
      </b-tab>
    </b-tabs>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BLink,
  BBadge, BDropdown, BDropdownItem, BPagination, BTabs, BTab,
} from 'bootstrap-vue'
import {getCode, getCodeColor, getCodeLabel, getCodeOptions, isEmpty} from "@core/utils/filter";
import {ref} from "@vue/composition-api";
import OutsideCompanyContact from "@/views/apps/company-new/outsideCompany/OutsideCompanyContact";
import OutsideCompanyBakAcc from "@/views/apps/company-new/outsideCompany/OutsideCompanyBakAcc";
import OutsideCompanyAttachments from "@/views/apps/company-new/outsideCompany/OutsideCompanyAttachments";
import OutsideCompanyEntity from "@/views/apps/company-new/outsideCompany/OutsideCompanyEntity";
import OutsideCompanyWarehouseStatement from "@/views/apps/company-new/outsideCompany/OutsideCompanyWarehouseStatement";
export default {
  components: {
    OutsideCompanyAttachments,
    OutsideCompanyBakAcc,
    OutsideCompanyContact,
    OutsideCompanyEntity,
    OutsideCompanyWarehouseStatement,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BTabs,
    BTab,
  },
  data() {
    return {
      company_id:ref(0),
    }
  },
  setup() {
    const getCompanyId = function (param){
      this.company_id = param
    }

    return {
      getCompanyId,
    }
  },
  created() {
    this.company_id = Number.parseInt(this.$route.query.id) || 0
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
